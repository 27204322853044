//Colours
$background-gradient: linear-gradient(45deg, #f47c22, #ee2058);
$color-reaction: #EE275E;
$background-placeholder: linear-gradient(45deg, rgb(240,240,240), rgb(250,250,250));

$breakpoint-small-phone: 320px;
$breakpoint-tablet: 760px;
$breakpoint-desktop: 1024px;

@mixin small-phone {
    @media (max-width: #{$breakpoint-small-phone}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$breakpoint-desktop}) {
        @content;
    }
}

@mixin tablet-desktop {
    @media (min-width: #{$breakpoint-tablet}) {
        @content;
    }
}

@mixin max-width($s) {
    @media (max-width: #{$s}) {
        @content;
    }
}

@mixin min-width($s) {
    @media (min-width: #{$s}) {
        @content;
    }
}