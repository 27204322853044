.c-header {
    box-sizing: border-box;
    color: white;
    flex-shrink: 0;
    letter-spacing: .3px;
    padding: $margin-step * 6;
    width: 100%;

    @include tablet-desktop {
        flex-direction: row;
    }

    &__link {
        margin-bottom: $margin-step * 6;
        text-decoration: none;
        padding-right: 30px;

        @include tablet-desktop {
            margin-bottom: 0;
            padding-right: 0;
        }
    }

    &__logo {
        height: 80px;

        @include desktop {
            height: 90px;
        }
    }

    &__text {
        color: white;
        font-family: 'Quicksand';
        font-size: 24px;
        font-weight: 700;
        padding-top: 20px;

        @include desktop {
            font-size: 26px;
        }
    }
}

.c-socialLinks {
    @include tablet-desktop {
        margin-left: auto;
    }

    &__link {
        text-decoration: none;
    
        &:first-of-type {
            margin-right: $margin-step * 4;

            @include max-width(340px) {
                margin-right: $margin-step * 2;
            }
        }
    }
    
    &__badge {
        height: 40px;

        @include tablet {
            height: 45px;
        }

        @include desktop {
            height: 50px;
        }
    }
}