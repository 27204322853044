@import 'base/variables';
@import 'base/flex';

html,
body {
    background: $background-gradient;
    color: rgba(black, .8);
    font-family: 'Quicksand';
    min-height: 100%;
    width: 100%;
}

* {
    margin: 0;
    padding: 0;
}

$margin-step: 4px;
@for $i from 1 through 6 {
    .u-margin#{$i} {
        margin: $margin-step * $i;
        &-t {
            margin-top: $margin-step * $i;
        }
        &-b {
            margin-bottom: $margin-step * $i;
        }
        &-l {
            margin-left: $margin-step * $i;
        }
        &-r {
            margin-right: $margin-step * $i;
        }
    }
}

.-hidden {
    display: none;
}

@import 'partials/components';
@import 'partials/header';
@import 'partials/footer';