.u-flex {
    display: flex;
}

.u-flex-column {
    display: flex;
    flex-direction: column;
}

.u-flex-center {
    align-items: center;
    display: flex;
}

.u-flex-center2 {
    display: flex;
    justify-content: center;
}

.u-flex-center-all {
    align-items: center;
    display: flex;
    justify-content: center;
}

.u-flex-grow {
    flex-grow: 1;
}