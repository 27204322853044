.c-main {
    flex-grow: 1;
    padding: 0 $margin-step * 4;
}

.l-post-wrapper {
    max-width: 400px;
    width: 100%;
}

.l-comments {
    background-color: white;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 1px rgba(black, 0.05);
    font-size: 14px;
    margin-top: -2px;
    padding-top: 2px;
    width: calc( 100% - 10px );
}

.c-loadMoreComments {
    background-color: rgba(black, .02);
    border-bottom: 1px solid rgb(240, 240, 240);
    cursor: pointer;
    font-size: .9em;
    opacity: .6;
    padding: $margin-step*3 $margin-step*4;
    padding-top: $margin-step*3 + 1;
    text-align: center;

    &.-disabled {
        background-color: rgba(black, .1);
        opacity: .5;
    }
}

.c-comment {
    border-top: 1px solid rgba(black, .03);
    font-size: 13px;

    @include tablet-desktop {
        font-size: 14px;
    }

    &:first-of-type {
        border: 0;
    }

    &__message {
        line-height: 1.4;
        padding: $margin-step * 3;
        padding-top: 0;
    }
}

.c-user {
    padding: $margin-step * 3;

    &.-og {
        font-size: .9em;
    }

    &.-comment {
        padding-bottom: $margin-step * 1;
    }

    &__info {
        align-items: flex-start;
        line-height: 1.4;
        margin-left: $margin-step * 2;
    }

    &__avatar {
        background: $background-placeholder;
        border-radius: 8px;
        height: 35px;
        text-indent: 99999px;
        width: 35px;

        .-og & {
            height: 45px;
            width: 45px;
        }

        .-comment & {
            height: 35px;
            width: 35px;
        }
    }

    &__name {
        color: lighten(black, 15%);
        font-size: 18px;
        font-weight: 700;

        .-shared & {
            font-size: 15px;
            line-height: 1.3;
        }

        .c-comment & {
            font-size: 16px;
        }

        &.-placeholder {
            background: $background-placeholder;
            color: transparent;
        }
    }

    &__extraText {
        color: rgba(black, .8);
        font-weight: 400;
    }

    &__sharedUsername {
        color: lighten(black, 15%);
        font-weight: 700;
    }

    &__time {
        color: lighten(black, 50%);
        font-size: .8em;

        &.-placeholder {
            background: $background-placeholder;
            color: transparent;
            margin-top: 4px;
        }
    }
}

.c-post {
    $inner-post-colour: lighten(#f9a14d, 30%);

    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 1px rgba(black, 0.05);
    font-family: "Quicksand";
    font-size: 18px;
    transition: font-size .1s;
    width: 100%;
    z-index: 2;

    @include tablet-desktop {
        font-size: 22px;
    }

    &__bottom {
        border-radius: 0 0 8px 8px;
        color: $color-reaction;
        font-size: .75em;
        padding: ($margin-step*2) ($margin-step*4);

        &.-og {
            background-color: darken($inner-post-colour, 3%);
            box-shadow: 0 2px 0 darken($inner-post-colour, 8%);
            margin: $margin-step * 2;
            margin-top: 0;
        }
    }

    &__time {
        &.-placeholder {
            background: $background-placeholder;
            color: transparent;
            margin-bottom: 4px;
            margin-top: 4px;
        }
    }

    &__views {
        &.-placeholder {
            background: $background-placeholder;
            color: transparent;
        }
    }

    &__content {
        background-color: white;
    }

    &__description {
        font-size: .8em;
        line-height: 1.5;
        padding: ($margin-step*2) ($margin-step*3);
        padding-top: $margin-step;

        .-og & {
            background: $background-gradient;
            color: white;
        }

        &.-no-post {
            font-weight: 700;
            opacity: 0.7;
            text-align: center;
        }

        &.-media {
            padding-top: $margin-step * 2;
        }

        &.-share {
            background-color: white;
            padding-bottom: $margin-step*3;
            padding-top: 0;
        }
    }

    &__descriptionPlaceholder {
        background: $background-placeholder;
        color: transparent;
    }

    &__reaction {
        height: 21px;
        margin-right: $margin-step * 2;

        &.-placeholder {
            display: none;
        }
    }

    &__reactionText {
        font-size: 1em;
        font-weight: 700;
        margin-right: $margin-step * 2;

        &:nth-of-type(2) {
            margin-left: $margin-step * 2;
        }

        &.-placeholder {
            color: transparent;
        }
    }

    &__stats {
        align-items: flex-end;
        color: rgba(#9A9A9A, .7);
        font-size: 0.8em;
        line-height: 1.2;
        margin-left: auto;
    }

    &__image {
        cursor: pointer;
        width: 100%;
    }

    &__video {
        width: 100%;
    }
}